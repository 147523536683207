import React, { forwardRef } from "react";
import Like from "../../../assets/pages/aboutus/Like.svg";
import HighlyExp from "../../../assets/pages/aboutus/HighlyExp.png";
import Modal from "../../Common/Modal";

interface BodytopSectionProps {
  className?: string;
  animate: boolean;
}

function CardTop() {
  return (
    <div className="w-full overflow-y-scroll md:overflow-hidden h-[440px] md:h-[400px] lg:h-[480px] bg-medMintGreen p-6 rounded-[20px] col-span-2 md:col-span-1">
      <div className="flex gap-[20px]">
        <img src={Like} alt="logo" />
        <p className="text-medMainGreen text-cap lg:text-2xl ">Highly Experienced Cultivation Team Leader</p>
      </div>
      <div className="border-[0.5px] border-medMainGreen my-[18px]"></div>
      <p className="text-medMainGreen text-base lg:text-xl">
        With many years of experience in craft cannabis from abroad, our cultivation leaders and team’s main objectives are to deliver high quality,
        top-shelf cultivars at the professional level and commercial scale in Thailand. Comprising both young, aspiring talents and experienced
        growers, we strive with our partners and researchers to continuously improve on our genetics, techniques and cultivation know-how to become
        one of the leading cannabis cultivation companies in the region.
      </p>
    </div>
  );
}

type PictureTopProps = {
  openModal: () => void;
};

function PictureTop({ openModal }: PictureTopProps) {
  return (
    <img
      src={HighlyExp}
      alt="pic"
      className="rounded-[20px] w-full h-[360px] md:h-[400px] lg:h-[480px] object-cover col-span-2 md:col-span-1 cursor-pointer"
      onClick={openModal}
    />
  );
}

const BodytopSection = forwardRef(({ className, animate }: BodytopSectionProps, ref: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <section
      className="box-border w-screen h-auto px-[24px] md:px-[48px] xl:px-[140px] py-10 sm:py-20 flex flex-col items-center transition-all ease-out duration-2500 translate-y-10"
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
    >
      <p className="text-b1 lg:text-h2 text-medWoodyGreen text-center mb-[100px]">Why Medicana?</p>
      <div className="bg-primary-Earthling w-full h-full grid grid-cols-2 gap-x-4 md:gap-x-6 lg:gap-x-10 gap-y-6 md:gap-y-10 lg:gap-y-20 max-w-screen-xl">
        <CardTop />
        <PictureTop openModal={openModal} />
      </div>
      <Modal closeModal={closeModal} isOpen={isOpen}>
        <img className="w-auto h-auto object-cover" src={HighlyExp} alt="pic" />
      </Modal>
    </section>
  );
});

export default BodytopSection;
