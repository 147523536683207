import React, { forwardRef } from "react";
import Likewhite from "../../../assets/pages/aboutus/Likewhite.svg";
import StateOf from "../../../assets/pages/aboutus/StateOf.png";
import Modal from "../../Common/Modal";

interface BodymidSectionProps {
  className?: string;
  animate: boolean;
}

type PictureProps = {
  openModal: () => void;
};

function PictureMid({ openModal }: PictureProps) {
  return (
    <img
      src={StateOf}
      alt="pic"
      className="rounded-[20px] w-full h-[360px] md:h-[400px] lg:h-[480px] col-span-2 md:col-span-1 hidden md:block cursor-pointer object-cover"
      onClick={openModal}
    />
  );
}

function PictureMidMobile({ openModal }: PictureProps) {
  return (
    <img
      src={StateOf}
      alt="pic"
      className="rounded-[20px] w-full h-[360px] md:h-[400px] lg:h-[480px] col-span-2 md:col-span-1 md:hidden object-cover"
      onClick={openModal}
    />
  );
}

function CardMid() {
  return (
    <div className="w-full h-[440px] md:h-[400px] lg:h-[480px] bg-medGold p-6 rounded-[20px] col-span-2 md:col-span-1">
      <div className="flex gap-[20px]">
        <img src={Likewhite} alt="logo" />
        <p className="text-white text-cap lg:text-2xl ">State-of-the-Art Facility</p>
      </div>
      <div className="border-[0.5px] border-white my-[18px]"></div>
      <p className="text-white text-base lg:text-xl">
        With best-in-class smart farming technologies optimized for medical-grade cannabis cultivation, our facility is designed for high consistency
        and maximum yields to meet the growing demand for high quality cultivars for medical use in Thailand.
      </p>
    </div>
  );
}

const BodymidSection = forwardRef(({ className, animate }: BodymidSectionProps, ref: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <section
      className="box-border w-screen h-auto px-[24px] md:px-[48px] xl:px-[140px] pb-10 sm:pb-20 flex flex-col items-center transition-all ease-out duration-2500 translate-y-10"
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
    >
      <div className="bg-primary-Earthling w-full h-full grid grid-cols-2 gap-x-4 md:gap-x-6 lg:gap-x-10 gap-y-6 md:gap-y-10 lg:gap-y-20 max-w-screen-xl">
        <PictureMid openModal={openModal} />
        <CardMid />
        <PictureMidMobile openModal={openModal} />
      </div>
      <Modal closeModal={closeModal} isOpen={isOpen}>
        <img className="w-auto h-auto object-cover" src={StateOf} alt="pic" />
      </Modal>
    </section>
  );
});

export default BodymidSection;
