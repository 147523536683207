import React, { forwardRef } from "react";
import { BannerText } from "../../Common/BannerText";

interface HeadSectionProps {
  className?: string;
  animate: boolean;
}

const HeadSection = forwardRef(({ className, animate }: HeadSectionProps, ref: any) => {
  return (
    <section className="bg-medBgGray w-screen h-fit pb-[140px] md:pb-[160px] xl:pb-[180px]" ref={ref}>
      {/* <p
        className="text-medMainGreen text-h2 lg:text-h1 leading-[72px] text-center transition-all ease-out duration-2500 translate-y-10"
        style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      >
        About Us
      </p> */}
      <BannerText animate={animate}>About Us</BannerText>
    </section>
  );
});

export default HeadSection;
