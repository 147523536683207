import React, { forwardRef } from "react";
import Like from "../../../assets/pages/aboutus/Like.svg";
import Regulation from "../../../assets/pages/aboutus/Regulation.png";
import CertMedicana from "../../../assets/pages/aboutus/CertMedicana.png";
import Modal from "../../Common/Modal";

interface BodybotSectionProps {
  className?: string;
  animate: boolean;
}

type PictureProps = {
  openModal: () => void;
};

function CardBot() {
  return (
    <div className="w-full h-[460px] sm:h-[600px] md:h-[520px] lg:h-[640px] xl:h-[800px] bg-medBgGray p-6 rounded-[20px] col-span-2 md:col-span-1">
      <div className="flex gap-[20px]">
        <img src={Like} alt="logo" />
        <p className="text-medMainGreen text-cap lg:text-2xl ">Expertise in Thai Regulation</p>
      </div>
      <div className="border-[0.5px] border-medMainGreen my-[18px]"></div>
      <p className="text-medMainGreen text-base lg:text-xl">
        As cannabis was decriminalized and legalized for medical use in Thailand, commercial cultivation of cannabis is regulated to meet medical
        standards. Our facility has been FDA approved for cultivation and distribution of cannabis floral biomass with the track record of being the
        first licensed indoor cannabis facility in Bangkok for 2022.
      </p>
    </div>
  );
}

function PictureBot({ openModal }: PictureProps) {
  return (
    <div className="box-border w-full h-[460px] sm:h-[600px] md:h-[520px] lg:h-[640px] xl:h-[800px] rounded-[20px] bg-medEarthling flex justify-center items-center col-span-2 md:col-span-1">
      <img src={CertMedicana} alt="pic" className="h-full w-full object-cover rounded-[20px] cursor-pointer" onClick={openModal} />
    </div>
  );
}

const BodybotSection = forwardRef(({ className, animate }: BodybotSectionProps, ref: any) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <section
      className="box-border w-screen h-auto px-[24px] md:px-[48px] xl:px-[140px] pb-10 md:pb-20 flex flex-col items-center transition-all ease-out duration-2500 translate-y-10"
      style={animate ? { opacity: 1, transform: "translateZ(0px)" } : { opacity: 0 }}
      ref={ref}
    >
      <div className="bg-primary-Earthling w-full h-full grid grid-cols-2 gap-x-4 md:gap-x-6 lg:gap-x-10 gap-y-6 md:gap-y-10 lg:gap-y-20 max-w-screen-xl">
        <CardBot />
        <PictureBot openModal={openModal} />
      </div>

      <Modal closeModal={closeModal} isOpen={isOpen}>
        <img className="w-auto h-auto object-cover" src={CertMedicana} alt="pic" />
      </Modal>
    </section>
  );
});

export default BodybotSection;
